import { type ReactElement, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import type { ITab } from '../../interfaces/AUI/ITabNavigation';

import TabNavigation from '../../components/TabNavigation';
import TitleOption from '../../components/TitleOption';

import { TABS } from './tabs/tabs';

import Programa from './tabs/Programa';
import Inscripcion from './tabs/Inscripcion';
import Hotel from './tabs/Hotel';

import AdvertenciaSeguridad from '../../components/AdvertenciaSeguridad';

import { ROUTES } from '../../constants/routes.constants';

import logo112 from '../../assets/images/logo-112-sesion.png';

import { searchKeyByValue } from '../../utils/routeUtils';
import type { ITitle } from '../../interfaces/model/ITitle';
import { getSesionPenariaTitle } from '../../services/title.service';

const TABS_ROUTES: Record<number, string> = {
	// 1: ROUTES.SESION_PLENARIA_PRESENTACION,
	2: ROUTES.SESION_PLENARIA_PROGRAMA,
	3: ROUTES.SESION_PLENARIA_INSCRIPCION,
	4: ROUTES.SESION_PLENARIA_HOTEL,
};

const SesionPlenaria = (): ReactElement => {
	const [title, setTitle] = useState<ITitle | undefined>(undefined);
	const [activeTab, setActiveTab] = useState<ITab['id']>(2);
	const history = useHistory();

	const loadTitle = async () => {
		const { data } = await getSesionPenariaTitle();
		setTitle(data);
	};

	useEffect(() => {
		loadTitle();
	}, []);

	const onTabChange = (id: ITab['id']) => {
		history.push(TABS_ROUTES[id]);
		setActiveTab(id);
	};

	useEffect(() => {
		setActiveTab(
			history.location.pathname === ROUTES.SESION_PLENARIA
				? 2
				: searchKeyByValue(TABS_ROUTES, history.location.pathname),
		);
	}, [history.location.pathname]);

	return (
		<div className="o-wrapper">
			{activeTab !== 3 && (
				<TitleOption
					color={title?.color}
					title={title?.titulo}
					date={title?.fecha}
					logo={logo112}
					isLogoCTN={title?.isLogoCTN}
				/>
			)}
			<TabNavigation tabs={TABS} activeTab={activeTab} className="u-mt-24" onTabChange={onTabChange} />
			<AdvertenciaSeguridad />
			<div className="u-mt-32">
				<Switch>
					{/*
					<Route
						exact
						path={[ROUTES.SESION_PLENARIA, ROUTES.SESION_PLENARIA_PRESENTACION]}
						component={Presentacion}
			/> */}
					<Route
						exact
						path={[ROUTES.SESION_PLENARIA, ROUTES.SESION_PLENARIA_PROGRAMA]}
						component={Programa}
					/>
					<Route exact path={ROUTES.SESION_PLENARIA_INSCRIPCION} component={Inscripcion} />
					<Route exact path={ROUTES.SESION_PLENARIA_INSCRIPCION_HOTEL} component={Hotel} />
				</Switch>
			</div>
		</div>
	);
};

export default SesionPlenaria;
