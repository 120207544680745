import React from 'react';
import { ReactComponent as LogoCTN } from '../../assets/images/logo-ctn.svg';

type TitleOptionProps = {
	readonly color?: string;
	readonly title?: string;
	readonly date?: string;
	readonly logo: string;
	readonly isLogoCTN?: boolean;
};

const TitleOption = ({ color, title, date, logo, isLogoCTN }: TitleOptionProps): React.ReactElement => (
	<div className="c-jb-title-option">
		<div className="c-jb-title-option__title">
			<img src={logo} alt={title} className="c-jb-title-option__logo" />
			<div>
				<h2 style={color ? { color } : {}}>{title}</h2>
				<p className="[ c-jb-title-option__date ] [ u-mt-8 ]">{date}</p>
			</div>
		</div>
		{isLogoCTN && (
			<div className="c-jb-title-option__ctn">
				Con el apoyo de:
				<LogoCTN />
			</div>
		)}
	</div>
);

export default TitleOption;
